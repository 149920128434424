<template>
  <div class="qr">
    <router-view></router-view>
  </div>
</template>

<script>
  import '@/plugins/vueQrcodeReader'

  export default {
    name: 'Qr',

    metaInfo() {
      return {
        title: 'Регистрация QR-кодов',
      }
    },
  }
</script>
